import axios from 'axios'
import router from '@/router'
import {
    Message
} from 'element-ui'
function getQueryParam (param) {
    // 获取当前页面的查询字符串
    var queryString = window.location.hash;
    // 去掉开头的问号
    if (queryString.split('?').length > 1) {
        queryString = queryString.split('?')[1]
        // 将查询字符串分割成键值对
        var params = queryString.split('&');
        // 遍历键值对数组
        for (var i = 0; i < params.length; i++) {
            var pair = params[i].split('=');
            // 如果找到匹配的参数名，返回对应的值
            if (pair[0] === param) {
                return decodeURIComponent(pair[1]);
            }
        }
    }
    // 如果没有找到匹配的参数名，返回null
    return null;
}
const token = getQueryParam("token");
// 保存token
sessionStorage.setItem("accessToken", token);
const http = axios.create({
    baseURL: process.env.VUE_APP_BASE_API,
    timeout: 1000 * 30,
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'accessToken': token,
    }
})

/**
 * 请求拦截
 */
http.interceptors.request.use(config => {
    let accessToken = sessionStorage.getItem("accessToken");
    if (accessToken) {
        config.headers.accessToken = encodeURIComponent(accessToken);
    }
    return config
}, error => {
    return Promise.reject(error)
})

/**
 * 响应拦截
 */
http.interceptors.response.use(async response => {
    if (response.data.code == 200) {
        return response.data.data;
    } else if (response.data.code == 400) {
        router.replace("/login");
    } else if (response.data.code == 401) {
        console.log("token失效，尝试重新获取")
        let refreshToken = sessionStorage.getItem("refreshToken");
        if (!refreshToken) {
            router.replace("/login");
        }
        // 发送请求, 进行刷新token操作, 获取新的token
        const data = await http({
            method: 'put',
            url: '/refreshToken',
            headers: {
                refreshToken: refreshToken
            }
        }).catch(() => {
            router.replace("/login");
        })

        sessionStorage.setItem("refreshToken", data.refreshToken);
        // 这里需要把headers清掉，否则请求时会报错，原因暂不详...
        if (typeof response.config.data != 'object') {
            response.config.headers = undefined;
        }
        // 重新发送刚才的请求
        return http(response.config)
    } else {
        Message({
            message: response.data.message,
            type: 'error',
            duration: 1500,
            customClass: 'element-error-message-zindex'
        })
        return Promise.reject(response.data)
    }
}, error => {
    switch (error.response.status) {
        case 400:
            Message({
                message: error.response.data,
                type: 'error',
                duration: 1500,
                customClass: 'element-error-message-zindex'
            })
            break
        case 401:
            router.replace("/login");
            break
        case 405:
            Message({
                message: 'http请求方式有误',
                type: 'error',
                duration: 1500,
                customClass: 'element-error-message-zindex'
            })
            break
        case 404:
        case 500:
            Message({
                message: '服务器出了点小差，请稍后再试',
                type: 'error',
                duration: 1500,
                customClass: 'element-error-message-zindex'
            })
            break
        case 501:
            Message({
                message: '服务器不支持当前请求所需要的某个功能',
                type: 'error',
                duration: 1500,
                customClass: 'element-error-message-zindex'
            })
            break
    }

    return Promise.reject(error)
})


export default http
